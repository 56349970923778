import React from 'react'
import './Hi.scss'

const Hi = () => {
    return (
        <div className="hi">
            <div className="hi_container">
                <h1 className="hi__title">Легалізація іноземців в Польщі</h1>
                <h2 className="hi__subtitle">
                    Ваш провідник у світ юридичної імміграції
                </h2>
            </div>
        </div>
    )
}

export default Hi
