import { FROM_US, ALL, LEGALIZATION, LIFE_IN_POLAND, OUR_LIFE } from '../variables/variables'
const UPDATE_BLOG = 'UPDATE_BLOG'


const initialState = {
    blogItems: [
        {   
            newsId: 1,
            id: FROM_US,
            title: 'Атлантіс тепер і в Кракові!',
            photo: 'img/krakow.jpg',
            deskription: "Давно спостерігаєш за нами, але проживаєш в Кракові? Ми стали на один крок ближче до тих,  хто проживає в Малопольському воєводстві 😌           Всі наші клієнти  знають, що почавши співпрацю з нашою командою - ви можете розслабитись і довіряти професіоналам в сфері легалізації іноземців на території Польщі, особливо тим, хто не знає польської мови чи просто не має часу займатись постійною перевіркою свого легального побуту. Наша команда має чималий досвід та компетенцію для надання допомоги іноземцям на найвищому рівні. В наші послуги - також входить допомога роботодавцям, зацікавлених легалізувати своїх працівників швидко та якісно. Кожна ситуація розглядається індивідуально, передбачаючи комплексні вирішення проблем. Все це створено для того, щоб Вам не доводилось стикатись з поширеними проблемами та помилками, і самостійно вирішувати юридичні, податкові чи адміністративні труднощі. Зв'яжіться з нами сьогодні, повідомте нас про допомогу, якої ви потребуєте,а ми зробимо за вас все інше 🙂",
            day: '04.04.2023',
        },
    ],
    blogActiveItem: [
        {   
            newsId: 1,
            id: FROM_US,
            title: 'Атлантіс тепер і в Кракові!',
            photo: 'img/krakow.jpg',
            deskription: "Давно спостерігаєш за нами, але проживаєш в Кракові? Ми стали на один крок ближче до тих,  хто проживає в Малопольському воєводстві 😌           Всі наші клієнти  знають, що почавши співпрацю з нашою командою - ви можете розслабитись і довіряти професіоналам в сфері легалізації іноземців на території Польщі, особливо тим, хто не знає польської мови чи просто не має часу займатись постійною перевіркою свого легального побуту. Наша команда має чималий досвід та компетенцію для надання допомоги іноземцям на найвищому рівні. В наші послуги - також входить допомога роботодавцям, зацікавлених легалізувати своїх працівників швидко та якісно. Кожна ситуація розглядається індивідуально, передбачаючи комплексні вирішення проблем. Все це створено для того, щоб Вам не доводилось стикатись з поширеними проблемами та помилками, і самостійно вирішувати юридичні, податкові чи адміністративні труднощі. Зв'яжіться з нами сьогодні, повідомте нас про допомогу, якої ви потребуєте,а ми зробимо за вас все інше 🙂",
            day: '04.04.2023',
        },
    ],
}

const blogReducer = (state = initialState, action) => {
    let newState;
    switch(action.type) {
        case UPDATE_BLOG:
            newState = {...state, blogActiveItem: [],}
                state.blogItems.forEach((el) => {
                    if(el.id === action.activeBlogItem){
                        newState.blogActiveItem.unshift(el)
                    } else if(action.activeBlogItem === ALL){
                        newState.blogActiveItem = [...newState.blogItems]
                    }
                })
            return newState
        default:
            return state
    }
}

export const UpdateBlogActionCreator = (id) => {
    return ({
        type: UPDATE_BLOG,
        activeBlogItem: id,
    })
}

export default blogReducer;