import React from "react";

const RealEstate = () => {
    return (
        <div className="realEstate">
            RealEstate
        </div>
    )
}

export default RealEstate;