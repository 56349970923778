import React from 'react'
import './PagesTop.scss'

const PagesTop = (props) => {
    return (
        <div initial="hidden" whileInView="visible" className="pagesTop">
            <h2 className="pagesTitle">{props.name}</h2>
        </div>
    )
}

export default PagesTop
