import React from 'react'
import './SecondCard.scss'

const SecondCard = (props) => {
    return (
        <div className="secondCard">
            <div
                className="secondCard__items"
                onClick={props.handleScrollClick}
            >
                <div className="secondCard__item">
                    1000+ задоволених клієнтів
                </div>
                <div className="secondCard__item">25+ послуг</div>
                <div className="secondCard__item">1000+ вирішених справ</div>
            </div>
        </div>
    )
}

export default SecondCard
