import React from 'react'
import PagesTop from '../pagesTop/PagesTop'
import BlogButtons from './blogButtons/BlogButtons'
import BlogContent from './blogContent/BlogContent'
import { useEffect } from 'react'
import c from './Blog.module.scss'

import BlueButton from '../blueButton/BlueButton'
import { useNavigate } from 'react-router-dom'

const Blog = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const blog = false
    const nav = useNavigate()

    return (
        <div className={c.blog}>
            {blog ? (
                <div className={c.blog__content}>
                    <PagesTop name={'блог'} />
                    <BlogButtons />
                    <BlogContent />
                </div>
            ) : (
                <div className={c.card_container}>
                    <div className={c.card}>
                        <div className={c.card__content}>
                            Блог в процесі реалізації. Зайдіть будь ласка
                            пізніше!
                        </div>
                        <div
                            onClick={() => nav('/main')}
                            className={c.card__button}
                        >
                            <BlueButton name={'головна'} link={'/main'} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Blog
