import { useNavigate } from 'react-router-dom'
import c from './FooterSuccess.module.scss'

import BlueButton from '../../blueButton/BlueButton'

const FooterSuccess = () => {
    const nav = useNavigate()
    return (
        <div className={c.card_container}>
            <div className={c.card}>
                <div className={c.card__content}>
                    Дякуємо за заявку. Незабаром наш спеціаліст зв'яжеться з
                    вами.
                </div>
                <div onClick={() => nav('/main')} className={c.card__button}>
                    <BlueButton name={'головна'} link={'/main'} />
                </div>
            </div>
        </div>
    )
}

export default FooterSuccess
