import React from "react";

const Auth = () => {
    return (
        <div className="auth">
            auth
        </div>
    )
}

export default Auth;