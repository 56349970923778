import React from "react";

const WebOffers = () => {
    return (
        <div className="webOffers">
            WebOffers
        </div>
    )
}

export default WebOffers;