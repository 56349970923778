const UPDATE_OFFERS = 'UPDATE_OFFERS'

const initialState = {
    offerItems: [
        {   
            id: 1,
            title: 'Тимчасовий побут',
            photo: 'img/pobyt.jpeg',
            offers: [
                {title: 'Подача на карту побуту', deskription: 'повний супровід в отриманні виду на проживання'},
                {title: 'Аналіз справи', deskription: 'професійна оцінка та складення запиту у відповідні органи'},
                {title: 'Реєстрація на отримання ІД картки', deskription: 'реєстрація та отримання картки з нашим працівником'},
                {title: 'Додаткові питання', deskription: 'допомога у разі виникнення проблем з отриманням виду на проживання, зміна даних і тд.'},
            ],
            deskription: 'Це документ, що підтверджує надання тимчасового дозволу на проживання на території  Польщі. Карта побиту дає можливість легально знаходитись на території Польщі та зоні ЄС без оформлення та продовження візи. Дозвіл на перебування дозволяє його власнику - відкривати бізнес, оформлювати кредит та іпотеку, реєструвати на своє ім’я авто чи нерухомість. В залежності від типу, дозвіл на перебування іноземця оформлюється строком: до 3-х років (тимчасовий) та до 10-ти років (сталий побит, карта резидента).',
            questionFirst: 'Що дає тимчасовий побут?',
            answerFirst: "Дає право безперервно перебувати в країні і офіційно працювати строком від  1 до  3 років (в залежності від терміну виданого рішення).",
            questionSecond: 'На якій підставі видається тимчасовий побут?',
            answerSecond: "Видається на підставі робочого контракту, навчання чи возз'єднання з родиною.",
        },
        {
            id: 2,
            title: 'Статус УКР',
            photo: 'img/status.jpg',
            offers: [
                {title: 'Процедура подання заяви', deskription: 'біженці, які потребують тимчасового захисту, повинні подати заяву в Управління з питань іноземців (Urząd do Spraw Cudzoziemców) або відповідний пункт прикордонного контролю.'},
                {title: 'Оцінка заяви', deskription: 'після подання заяви на тимчасовий захист уповноважені органи проводять оцінку обставин і перевіряють, чи відповідає заява вимогам законодавства щодо біженців.'},
                {title: 'Права і обмеження', deskription: "особі з тимчасовим статусом захищеної особи надаються певні права, такі як доступ до освіти, медичних послуг і соціальної допомоги. Водночас, існують певні обмеження, зокрема пов'язані з роботою і правом на перебування в окремих зонах."},
                {title: 'Продовження статусу', deskription: 'тимчасовий статус захищеної особи надається на певний строк. Під час цього періоду біженець може подати заяву на тимчасове проживання, якщо існують підстави для його отримання.'},
                {title: 'Інтеграція та переселення', deskription: "Польща розробляє програми і політику для інтеграції біженців у суспільство, надаючи підтримку у вивченні мови, отриманні навчання та пошуку роботи."},
            ],
            deskription: 'Статус UKR. Статус «УКР» дозволяє переселенцям з України в Польщі вільно користуватись соціальним та медичним захистом у Республіці Польща. Також громадяни мають доступ до ринку праці та можуть займатись підприємництвом.',
            questionFirst: 'Як перевірити чи маю я статус УКР?',
            answerFirst: 'Звернути увагу потрібно на свій Pesel, на пункт Status cudzoziemca. Якщо напроти нього стоїть прочерк – зі статусом UKR все добре. Якщо замість прочерку щось інше – потрібно звертатися до уженду.',
            questionSecond: 'Я виїхав закордон. Чи зберігається мій статус УКР? ',
            answerSecond: `Як зазначає українське посольство у Польщі, єдиною підставою для скасування статусу UKR (PESEL) є стаття 11 пункт 2 "Закону про допомогу громадянам України у зв'язку зі збройним конфліктом на території цієї держави, якщо особа виїжджає з території Польщі на строк понад 1 місяць."`,
        },
        {   
            id: 3,
            title: 'Сталий побут',
            photo: 'img/pobyt.jpeg',
            offers: [
                {title: 'Подача на карту побуту', deskription: 'повний супровід в отриманні виду на проживання'},
                {title: 'Аналіз справи', deskription: 'професійна оцінка та складення запиту у відповідні органи'},
                {title: 'Реєстрація на отримання ІД картки', deskription: 'реєстрація та отримання картки з нашим працівником'},
                {title: 'Додаткові питання', deskription: 'допомога у разі виникнення проблем з отриманням виду на проживання, зміна даних і тд.'},
            ],
            deskription: 'Це документ, що підтверджує особливий статус іноземного громадянина в Польщі і дозволяє перебувати в країні довше, ніж за візами. Це вид на проживання, який дозволяє без оформлення віз перетинати кордон Польщі необмежену кількість разів, працювати і вчитися без додаткових дозволів.',
            questionFirst: 'Що дає сталий побут?',
            answerFirst: 'Сталий побут. Документ, що підтверджує постійне місце проживання на території РП. Термін дії - 10 років. Видається на підставі польського походження (Карти Поляка) або 5 років безперервного проживання з картою czasowego pobytu.',
            questionSecond: 'На які бонуси можна розраховувати при отриманні сталого побуту?',
            answerSecond: ' Безкоштовна медицина, навчання в польських школах та ВНЗ, можливість брати кредити в польських банках та безперешкодне відвідування країн Шенгенської зони в туристичних цілях.',
        },
        {   
            id: 4,
            title: 'Карта резидента',
            photo: 'img/rezydent.jpg',
            offers: [
                {title: 'Подача на карту побуту', deskription: 'повний супровід в отриманні виду на проживання'},
                {title: 'Аналіз справи', deskription: 'професійна оцінка та складення запиту у відповідні органи'},
                {title: 'Реєстрація на отримання ІД картки', deskription: 'реєстрація та отримання картки з нашим працівником'},
                {title: 'Додаткові питання', deskription: 'допомога у разі виникнення проблем з отриманням виду на проживання, зміна даних і тд.'},
            ],
            deskription: 'Дозвіл на перебування довгострокового резидента ЄС — це дозвіл, який дає право на постійне проживання в Польщі. Рішення про надання дозволу на перебування видається на невизначений строк. Сама карта перебування дійсна впродовж 5 років, що означає, що кожні 5 років треба міняти карту в рамках процедури перевипуску карти.',
            questionFirst: 'Що дає карта резидента?',
            answerFirst: 'Карта резидента. Видається строком на 5 років. Даний документ може отримати іноземець, який легально та безперервно проживає на території Польщі протягом останніх 5 років і може підтвердити наявність постійного джерела доходу протягом останніх 3 років',
            questionSecond: 'Чи можу я подорожувати по карті резидента?',
            answerSecond: 'Дозвіл на перебування довгострокового резидента ЄС видане в Польщі, дає право на відвідування інших країн Шенгенської зони. Однак перебування іноземця на цій підставі не може перевищувати 90 днів упродовж 180 днів. Якщо іноземець планує перебувати в цій країні довше, ніж дозволяє зазначений ліміт, він мусить легалізуватися відповідно до законодавства цієї країни.',
            questionThird: 'za co panie Vladyslawie mam jeszcze zaplacic?',
            answerThird: 'lorem ipsut dolor sit amet lorem ipsut dolor sit amet lorem ipsut dolor sit ametlorem ipsut dolor sit ametlorem ipsut dolor sit ametlorem ipsut dolor sit amet'
        },
        {
            id: 5,
            title: 'Бізнес',
            photo: 'img/analiz.jpeg',
            offers: [
                {title: 'Відкриття ФОП (JDG)', deskription: 'це польський еквівалент способу ведення бізнесу як фізична особа-підприємець (ФОП). Ця форма ведення бізнесу в Польщі доступна не лише польським громадянам, а й деяким іноземцям, що мешкають у Польщі. За детальною інформацією звертайтесь до наших менеджерів. '},
                {title: 'Відкриття ТОВ (spółka)', deskription: "для того, щоб відкрити ТОВ у Польщі, не потрібно, щоб один з його співвласників мав польське громадянство. Ви, як іноземець, маєте повне право на володіння і самостійне управління ТОВ в Польщі. Для комфортного відкриття бізнесу в Польщі - скористайтесь послугами нашої компанії і забудьте про всі можливі проблеми чи питання, які можуть у вас виникнути в процесі реєстрації своєї діяльності. "},
                {title: 'Аналіз справи', deskription: "професійна оцінка персональним менеджером вашої заявки пов'язаної з бізнесом в Польщі."},
            ],
            deskription: 'Польща має простий процес реєстрації бізнесу і широкий спектр бізнес-структур, які ви можете обрати, таких як акціонерні товариства, товариства з обмеженою відповідальністю, фізичні особи підприємці та інші. Важливо отримати всю необхідну інформацію і консультацію щодо організації бізнесу від юридичних консультантів або професійних бухгалтерів.',
            questionFirst: 'Які переваги в співпраці саме з вами?',
            answerFirst: "Впевненість в тому, що фірма була зареєстрована належним чином, під ваші потреби, що дозволить почати ваш бізнес протягом 1 дня.",
            questionSecond: 'Що я отримаю після звернення до вас? ',
            answerSecond: "Повністю налаштований процес для ведення бізнесу в Польщі.  А також зареєстровану компанію, яка має NIP, REGON і номер KRS, а при необхідності протягом одного дня може бути зареєстрована як податник VAT. Також ми допоможемо відкрити банківський рахунок під ваші потреби."
        },
        {   
            id: 6,
            title: 'Громадянство',
            photo: 'img/passport.jpeg',
            deskription: "Воєвода може визнати іноземця польським громадянином за заявою того ж іноземця, а в разі неповнолітньої особи – за заявою її законних представників. Визнання має форму адміністративного рішення і залежить від виконання іноземцем формальних умов.",
            questionFirst: 'Яка процедура отримання громадянства?',
            answerFirst: "Визнання польським громадянином відбувається за заявою зацікавленої особи. Заява про визнання громадянином Польщі подається до воєводи, компетентного за місцем фактичного проживання іноземця (тобто, місця, в якому зосереджений центр життєвих інтересів іноземця). Рішення про визнання іноземця громадянином Польщі видає воєвода, компетентний за місцем проживання особи, якої стосується провадження.",
            questionSecond: "Що треба робити, щоб стати визнаним громадянином Польщі?",
            answerSecond: 'Аби бути визнаним громадянином Польщі, Ви повинні знати польську мову на рівні В1 – потрібно офіційне підтвердження, сертифікат.'
        },
        {   
            id: 7,
            title: 'Для водія',
            photo: 'img/auto.jpg',
            offers: [
                {title: 'Заміна водійського посвідчення', deskription: 'Видача та заміна водійського посвідчення на території Польщі '},
                {title: 'Реєстрація авто', deskription: "Допомога при реєстрації для свого приватного транспортного засобу в Польщі "},
            ],
            deskription: 'Наша компанія надає швидкі та надійні послуги з реєстрації автомобілів та отримання водійських прав. Ми маємо досвід та знання, необхідні для ефективного виконання всіх процедур та вимог. Наша команда кваліфікованих експертів готова надати професійну допомогу та поради на кожному етапі. Ми пропонуємо зручні рішення, які дозволять вам економити час та зусилля при оформленні документів.',
            questionFirst: 'Чи займатесь ви заміною українських прав на польські? І як це відбувається?',
            answerFirst: 'Так! Для цього потрібно прийти до нас в офіс, де ви отримаєте консультацію за вашою заявкою. Після заключення котракту - ми розпочинаємо процес співпраці з вашим персональним менеджером.',
            questionSecond: 'Кому потрібно замінювати водійське посвідчення?',
            answerSecond: 'Процедура необхідна тим, у кого зіпсувалось посвідчення, закінчився термін дії документа або при зміні персональних даних.',
        },
        {
            id: 8,
            title: 'Шлюб',
            photo: 'img/shlub.jpeg',
            offers: [
                {title: 'Реєстрація шлюбу', deskription: 'підготовка необхідного пакету документів для  органів державної реєстрації актів цивільного стану'},
                {title: 'Розірвання шлюбу', deskription: "формальне припинення дійсного шлюбу між подружжям"},
            ],
            deskription: 'Реєстрація актів цивільного стану для іноземців в Польщі. За допомогою наших спеціалістів - ви позбудетесь всіх формальностей, необхідних для реєстрації шлюбу на території Польщі',
            questionSecond: 'Чи потрібно перекладати українські документи на польську мову?',
            answerSecond: "Так. І це має бути виключно присяжний переклад (максимально точний переклад оригінального документа, який засвідчується присяжним перекладачем)",
            questionFirst: 'Чи можу я оформити польське свідоцтво про шлюб?',
            answerFirst: 'Так. За допомогою наших спеціалістів - ваша пристуність буде зведена до мінімуму в органах державної реєстрації актів цивільного стану',
        },
        {   
            id: 9,
            title: 'Нерухомість',
            photo: 'img/real.jpeg',
            offers: [
                {title: 'Допомога в отриманні іпотечного кредиту', deskription: 'наші партнери гарантують Вам повний безкоштовний супровід в отриманні іпотечного кредиту (тільки для наших клієнтів). '},
                {title: 'Послуги ріелтора', deskription: "для тих, хто планує виїхати в Польщу на навчання, на роботу або на постійне місце проживання, виникає питання оренди житла. Наша команда надає Вам можливість знайти ідеальний варіант житла, який підійде і по бюджету, і за запитами."},
              ],
            deskription: ' Якщо ви хочете купити / продати чи здати в оренду свою нерухомість - пропонуємо вам скористатися послугами нашої компанії. Наші менеджери допоможуть вам обрати найвигідніші пропозиції, актуальні для ринку нерухомості сьогодні.',
            questionFirst: 'Скільки коштує послуга отримання іпотечного кредиту?',
            answerFirst: 'Якщо ви звертаєтесь до нас - і хочете оформити іпотечний кредит, для вас це буде БЕЗКОШТОВНО. Жодних додаткових оплат та провізій.',
            questionSecond: 'Як розрахувати свою кредитоспроможність та щомісячний платіж?',
            answerSecond: 'Кожні 100 000 злотих кредиту - це платіж у розмірі +-775 злотих (інформація актуальна на червень 2023 року) на місяць. Якщо людина хоче купити квартиру, наприклад, за 440 000 злотих і має власний внесок 10%, то щомісяця для погашення кредиту треба буде платити по +-3 500 злотих (інформація актуальна на червень 2023 року).',
        },
        {   
            id: 10,
            title: 'Інші послуги',
            photo: 'img/inni.jpeg',
            deskription: 'За індивідуальними зверненнями потенційних та вже існуючих клієнтів - наша команда вирішує питання та представляє вас в органах місцевого самоврядування.',
            offers: [
               {title: 'Pesel', deskription: "(Powszechny Elektroniczny System Ewidencji Ludności) -  це номер загальної системи електронного обліку населення, цифровий символ, що призначається фізичній особі та дозволяє ідентифікувати її.  PESEL налічує 11 цифр та містить інформацію про дату народження людини, її стать, порядковий номер та контрольний номер."},
                {title: 'PKK', deskription: 'профіль кандидата у водії – набір даних, які ідентифікують особу, яка хоче отримати водійські права. На підставі КК визнають кандидата у водії контори, автошколи, та інші установи, які беруть участь у процесі отримання водійських прав.'},
                {title: "Отримання спадщини", deskription: "допомога в оформленні заяви про прийняття спадщини за місцем відкриття спадщини"},
                {title: "Присяжний переклад", deskription: 'tłumaczenie przysięgłe або завірений переклад – це максимально точний переклад оригінального документа, який засвідчується присяжним перекладачем'},
                {title: "Відкриття банківського рахунку", deskription: "відкриття банківського рахунку стане в нагоді для іноземців, котрі планують довгострокове перебування на території Республіки Польща. Докладнішу інформацію ви можете отримати в наших спеціалістів."}
            ],        
            questionFirst: 'Я хочу отримати послугу, яка не вказана у вас на сторінці.',
            answerFirst: 'За індивідуальним зверненням кожного клієнта - ми розглядаємо можливість виконання та надання вашої послуги. Якщо це можливо - для вас буде створений індивідуальний контракт, поза пропонованими послугами.',
            questionSecond: 'Я з іншого міста, чи можу я скористатись вашими послугами?',
            answerSecond: 'Так, ми представляємо наших клієнтів по всій території Польщі.',
        },
    ],
    offersActiveItem: {   
        id: 1,
        title: 'Тимчасовий побут',
        photo: 'img/pobyt.jpeg',
        offers: [
            {title: 'Подача на карту побуту', deskription: 'повний супровід в отриманні виду на проживання'},
            {title: 'Аналіз справи', deskription: 'професійна оцінка та складення запиту у відповідні органи'},
            {title: 'Реєстрація на отримання ІД картки', deskription: 'реєстрація та отримання картки з нашим працівником'},
            {title: 'Додаткові питання', deskription: 'допомога у разі виникнення проблем з отриманням виду на проживання, зміна даних і тд.'},
        ],
        deskription: 'Це документ, що підтверджує надання тимчасового дозволу на проживання на території  Польщі. Карта побиту дає можливість легально знаходитись на території Польщі та зоні ЄС без оформлення та продовження візи. Дозвіл на перебування дозволяє його власнику - відкривати бізнес, оформлювати кредит та іпотеку, реєструвати на своє ім’я авто чи нерухомість. В залежності від типу, дозвіл на перебування іноземця оформлюється строком: до 3-х років (тимчасовий) та до 10-ти років (сталий побит, карта резидента).',
        questionFirst: 'Що дає тимчасовий побут?',
        answerFirst: "Дає право безперервно перебувати в країні і офіційно працювати строком від  1 до  3 років (в залежності від терміну виданого рішення).",
        questionSecond: 'На якій підставі видається тимчасовий побут?',
        answerSecond: "Видається на підставі робочого контракту, навчання чи возз'єднання з родиною.",
    },
}

const offersReducer = (state = initialState, action) => {
    let newState;
    switch(action.type) {
        case UPDATE_OFFERS:
            newState = {...state}
                initialState.offerItems.forEach((el) => {
                    if(el.id === action.activeOffersItem){
                        newState.offersActiveItem = el
                    }
                })
            return newState
        default:
            return state
    }
}

export const UpdateOffersActionCreator = (id) => {
    return ({
        type: UPDATE_OFFERS,
        activeOffersItem: id,
    })
}

export default offersReducer;